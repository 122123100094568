import {HTTP} from '../utils/axios'

class AdsModel extends HTTP {
  /*首页渲染*/
  index(key = '',page = 1) {
      let data = {}
      data.key = key
      data.page = page
      return this.request({
          url: 'board/ads',
          method: "GET",
          data: data
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/ads",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/ads/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy( id) {
    return this.request({
        url: 'board/ads/' + id,
        method: "DELETE",
    })
  }

  /*置顶*/
  set_top(value) {
    return this.request({
        url: 'board/ads/set_top',
        method: "PUT",
        data: value
    })
  }

  /*后移*/
  move_next(value) {
    return this.request({
        url: 'board/ads/move_next',
        method: "PUT",
        data: value
    })
  }
}

export {AdsModel}