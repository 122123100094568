<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入广告名称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          清空
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="showModal('new')"> 新增广告 </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
      <template slot="link_addr" slot-scope="text">
        <a :href="text">{{ text }}</a>
      </template>
      <template slot="visible" slot-scope="text, record">
        <a-switch :default-checked="text" @change="onChange(record)" />
      </template>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleById(record)">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleSetTop(record)" class="green"
          >置顶</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleMoveNext(record)" class="green"
          >后移</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" class="delete" @click="handleDelete(record)"
          >删除</a
        >
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="广告名称">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '20字以内' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="链接地址">
          <a-input
            v-decorator="[
              'link_addr',
              { rules: [{ required: true, message: '请输入链接地址' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="广告图片">
          <span class="redFont"
            >支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图片尺寸280*170px，推荐图标尺寸64*64px</span
          >
          <a-upload
            name="bg_image"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload1"
          >
            <img v-if="imageUrl1" :src="imageUrl1" alt="avatar" />
            <div v-else>
              <a-icon :type="loading1 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
          <a-upload
            name="icon"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload2"
          >
            <img v-if="imageUrl2" :src="imageUrl2" alt="avatar" />
            <div v-else>
              <a-icon :type="loading2 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
          <a-upload
            name="f_icon"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload3"
          >
            <img v-if="imageUrl3" :src="imageUrl3" alt="avatar" />
            <div v-else>
              <a-icon :type="loading3 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
          <div class="upload-word">
            <span>广告背景图片</span>
            <span>广告图标-正常</span>
            <span>广告图标-焦点</span>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { AdsModel } from "../../models/ads";
import Pagination from "../../components/Pagination";
import upload from "../../utils/oss";
import { mapMutations } from "vuex";
const adsModel = new AdsModel();
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "广告名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "链接地址",
    dataIndex: "link_addr",
    key: "link_addr",
    scopedSlots: { customRender: "link_addr" },
  },
  {
    title: "是否显示",
    dataIndex: "visible",
    key: "visible",
    scopedSlots: { customRender: "visible" },
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      formLayout: "horizontal",
      form2: this.$form.createForm(this, { name: "coordinated" }),
      flag: "",
      editId: 0,
      loading1: false,
      loading2: false,
      loading3: false,
      total: 0,
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      title: "",
      current: 1,
    };
  },

  created() {
    this.queryAdsList();
  },

  methods: {
    onChange(record) {
      adsModel.update({ visible: !record.visible }, record.id).then((res) => {
        if (res.code == 0) {
          this.$notification.open({
            message: "提示",
            description: res.message,
            duration: 2,
          });
          this.queryAdsList();
        }
      });
    },

    handleById(record, flag) {
      this.flag = flag;
      this.imageUrl1 = record.bg_image;
      this.imageUrl2 = record.icon;
      this.imageUrl3 = record.f_icon;
      this.editId = record.id;
      this.visible = true;
      this.title = "编辑";
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
          link_addr: record.link_addr,
        });
      });
    },

    handleDelete(record) {
      const confirmText = ["确定删除当前广告吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        cancelText: "取消",
        okText: "确定",
        onOk: () => {
          adsModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryAdsList();
            }
          });
        },
        onCancel() {},
      });
    },

    handleSetTop(record) {
      this.$confirm({
        content: "确定要置顶吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          adsModel.set_top({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryAdsList();
            }
          });
        },
        onCancel() {},
      });
    },

    handleMoveNext(record) {
      this.$confirm({
        content: "确定要后移吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          adsModel.move_next({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryAdsList();
            }
          });
        },
        onCancel() {},
      });
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryAdsList(key, 1);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryAdsList();
    },

    showModal(flag) {
      this.flag = flag;
      this.visible = true;
      this.imageUrl1 = "";
      this.imageUrl2 = "";
      this.imageUrl3 = "";
      this.title = "新增";
      console.log(this.visible);
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryAdsList(values.key, page);
        }
      });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        values.bg_image = this.imageUrl1;
        values.icon = this.imageUrl2;
        values.f_icon = this.imageUrl3;
        if (!err) {
          if (this.flag == "new") {
            adsModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryAdsList();
              }
            });
          } else {
            adsModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryAdsList();
              }
            });
          }
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
      this.imageUrl1 = "";
      this.imageUrl2 = "";
      this.imageUrl3 = "";
    },

    queryAdsList(key, page) {
      this.loading = true;
      adsModel.index(key, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },

    beforeUpload1(file) {
      this.loading1 = true;
      upload(file, "softmall-images", {}).then((res) => {
        this.loading1 = false;
        if (res) {
          this.imageUrl1 = 'https://softmall-images.oss-cn-qingdao.aliyuncs.com/'+ res;
        }
      });
    },

    beforeUpload2(file) {
      this.loading2 = true;
      upload(file, "softmall-images", {}).then((res) => {
        this.loading2 = false;
        if (res) {
          this.imageUrl2 = 'https://softmall-images.oss-cn-qingdao.aliyuncs.com/'+ res;
        }
      });
    },

    beforeUpload3(file) {
      this.loading3 = true;
      upload(file, "softmall-images", {}).then((res) => {
        this.loading3 = false;
        if (res) {
          this.imageUrl3 = 'https://softmall-images.oss-cn-qingdao.aliyuncs.com/'+ res;
        }
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.ant-upload-picture-card-wrapper {
  width: 110px;
  margin-right: 10px;
}
.ant-upload.ant-upload-select-picture-card {
  margin-bottom: -14px;
  img {
    width: 100%;
  }
}
.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  color: #333;
}
</style>
